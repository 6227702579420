import { uris } from "const";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import {
  Airdrops,
  Asset,
  AssetsList,
  Cashback,
  ColdStaking,
  Exchange,
  Main,
  Staking,
  Mining,
  NotFound,
  ContactRedirect,
  Business,
  AssetPage
} from "routes";
import { Company } from "routes/Company";
import { Developers } from "routes/Developers";
import { DevelopersNodes } from "routes/DevelopersNodes";
import { DevelopersStaking } from "routes/DevelopersStaking";
import { Giveaways } from "routes/Giveaways";
import { Marketing } from "routes/Marketing";
import { Nodes } from "routes/Nodes";
import { Power } from "routes/Power";
import { PowerList } from "routes/PowerList";
import { PrivacyPolicy } from "routes/PrivacyPolcity";
import { StakingNodes } from "routes/StakingNodes";
import { Terms } from "routes/Terms";
import { Wallet } from "routes/Wallet";
import { ThemeProvider } from "styled-components";

const theme = {
  purple: "#08073C",
  black: "black",
  russianBlack: "#1B1E23",
  white: "white",
  cyan: "#03A3D6",
  cyanDark: "#007297",
  lightGray: "#D0D0D0",
  darkGray: "#A8A8A8",
};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index path="/individuals" element={<Navigate to="/" />} />
          <Route path="/individuals/contact" element={<ContactRedirect />} />
          <Route index path="/" element={<Main />} />
          <Route index path="/homepage" element={<Navigate to="/" />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/mining" element={<Mining />} />
          <Route path="/cashback" element={<Cashback />} />
          <Route path="/airdrops" element={<Airdrops />} />
          <Route path="/cold-staking" element={<ColdStaking />} />
          <Route path="/asset/:assetName" element={<Asset />} />
          <Route path="/cold-staking/asset/:code" element={<AssetPage />} />
          <Route path="/assets/:assetName" element={<Asset />} />
          <Route path="/assets" element={<AssetsList />} />
          <Route path="/company" element={<Company />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path={uris.developers.index} element={<Developers />} />
          <Route path={uris.developers.nodes} element={<DevelopersNodes />} />
          <Route
            path={uris.developers.staking}
            element={<DevelopersStaking />}
          />
          <Route
            path={uris.agreements.termsAndConditions}
            element={<Terms />}
          />
          <Route
            path={uris.agreements.privacyPolicy}
            element={<PrivacyPolicy />}
          />
          <Route path={uris.landings.power} element={<PowerList />} />
          <Route path={uris.business.index} element={<Business />} />
          <Route path={uris.business.staking} element={<StakingNodes />} />
          <Route path={uris.business.nodes} element={<Nodes />} />
          <Route path={uris.business.wallet} element={<Wallet />} />
          <Route path={uris.business.marketing} element={<Marketing />} />
          <Route path={uris.business.giveaways} element={<Giveaways />} />
          <Route path={uris.business.power} element={<Power />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
